.notifications-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 1rem;
  width: 100%;
  padding-right: .5rem;
  align-items: flex-end;
  z-index: 1000;
  &__item {
    margin-bottom: .5rem;
    min-width: 310px;
    width: fit-content;
  }
}
