.vehicle-element {
  margin: 1rem 0;
  &__title {
    @include regular-text;
    font-weight: 700;
    color: $black;
    margin-bottom: .5rem;
  }
  &__content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @include mobile {
      flex-direction: column;
    }
    &--image-container {
      width: 200px;
      @include mobile {
        width: 100%;
      }
    }
    &--list {
      font-size: .9rem;
      &--element {
        color: $gray;
      }
      &--end-element {
        margin-top: 1rem;
        color: $gray;
        margin-left: -1rem;
      }
    }
  }
}
