.services-section {
  padding: 2.5rem;
  background-color: $ligthgray;
  &__title {
    @include title-text;
    text-align: center;
  }
  &__container {
    padding: 5rem 0;
    display: flex;
    flex-direction: row;
    @include tablet {
      flex-direction: column;
      padding: 0;
    }
    &--element {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      @include tablet {
        margin: 3rem 0;
      }
      .service-element {
        &__title {
          @include title-text;
          text-align: center
        }
        &__description {
          @include regular-text;
          margin: .5rem 0;
          color: $gray;
          width: 60%;
          text-align: center;
          @include tablet {
            width: 100%;
          }
        }
      }
    }
  }
}
