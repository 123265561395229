.map-popup-container {
  &__title {
    @include regular-text;
    font-weight: 700;
    color: $black
  }
  .line {
    @include line;
    margin: 1rem 0;
    border-width: 1px
  }
  &__content {
    @include regular-text;
    font-size: .9rem;
    color: $gray;
  }
}

// Overriding Leaflet styles
.leaflet-container {
  width: 100%;
  height: 100%;
  font: inherit;
}

.leaflet-tooltip {
  border-radius: 4px;
  opacity: 1 !important;
  padding: 2.5rem;
  color: inherit;
  &-top {
    &::before {
      display: none;
    }
  }
}
