// Breakpoints
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1500px;

// Element mixins
@mixin line {
  width: 150px;
  border-bottom: 2px solid $primary;
}

// Media query mixins
@mixin mobile {
  @media screen and (max-width: $sm) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $md) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: $lg) {
    @content;
  }
}

@mixin largeDesktop {
  @media screen and (min-width: $xl) {
    @content;
  }
}

// Layout mixins
@mixin container-left-padding {
  padding-left: 24px;
  @include mobile {
    padding-left: 16px;
  }
}

@mixin container-rigth-padding {
  padding-right: 24px;
  @include mobile {
    padding-right: 16px;
  }
}
