.delete-account-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-image: url('./Assets/background-image.png');
    height: 83.3vh;
    background-size: 100% 100%;
    padding: 5%;
    @include tablet {
        height: 100vh;
        padding: 10%;
        padding-top: 150px;
    }
    @include mobile {
        height: 100vh;
        padding: 2%;
        padding-top: 100px;
    }

    .title-container {
        font-size: 50px;
        font-weight: bold;
        color: white;
        @include tablet {
            font-size: 40px;
        }
        @include mobile {
            font-size: 30px;
        }
    }

    .form-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        max-width: 800px;
        height: 100vh;
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 5px;
        padding: 50px;
        @include tablet {
            flex-direction: column;
            height: 80vh;
        }

        .divider {
            background-color: #EEEEEE;
            height: 100%;
            width: 2px;
            border-radius: 1px;
            margin: 30px; 
            @include tablet {
                width: 100%;
                height: 2px;
            }
            @include mobile {
                width: 100%;
                height: 2px;
            }
        }

        .form-container-left {
            width: 100%;
            max-width: 400px;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            text-align: left;
            @include tablet {
                height: 300px;
            }

            .sub-title {
                font-size: 18px;
                font-weight: bold;
                color: black;
                line-height: 20px;
            }

            .description {
                font-size: 14px;
                font-weight: 400;
                color: black;
                margin-top: 20px;
            }

            .image-text-container {
                margin-top: 20px;
                .image-text {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    a {
                        margin-left: 10px;
                        font-size: 16px;
                        color: grey;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }

        .form-container-right {
            width: 100%;
            max-width: 400px;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            text-align: left;

            .sub-title-container {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .sub-title {
                    font-size: 18px;
                    font-weight: bold;
                    color: black;
                    line-height: 20px;
                }

                .description {
                    font-size: 14px;
                    font-weight: 400;
                    color: black;
                    margin-top: 20px;
                }
            }

            .form-filed-container {
                margin: 20px 0px;
            }
        }
    }
}
