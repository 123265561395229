.privacy-policy-section {
    width: 100%;
    align-items: center;
    margin: 1% 0;
    justify-content: center;
    padding-bottom: 2%;

    @include tablet {
        padding-bottom: 4rem;
    }

    .title {
        font-weight: 700;
        font-size: 2.5rem;
        text-align: center;

        @include mobile {
            font-size: 2.5rem;
            margin-top: 6rem;
        }
    }

    .description {
        @include regular-text;
        margin-top: 3%;
        padding: 0 20%;
        .subtitle {
            font-weight: 700;
        }

        .link-style {
            text-decoration: none;
            font-weight: 700;
            color: black;
            &:hover {
                color: $primary;
            }
        }

        @include mobile {
            font-size: 14px;
            padding: 0 10%;
        }
    }
}
