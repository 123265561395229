@import './Blocks/VehicleElement/VehicleElement';

.service-detail-screen {
  padding-top: 70px; // Height of the navigation bar
  min-height: calc(100vh - 162px); // Total screen size minus the Footer
  height: 100%;
  position: relative;
  padding-bottom: 2rem;
  @include tablet {
    height: auto;
  }
  &__container {
    display: flex !important;
    flex-direction: row;
    height: 100%;
    z-index: 1;
    gap: 2rem;
    @include tablet {
      flex-direction: column;
    }
  }
  &__left {
    flex: 1;
    &--title {
      @include title-text;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      &--icon {
        height: 2.5rem;
      }
    }
    &--description {
      @include regular-text;
      color: $gray;
      margin: 2rem 0;
      color: $gray;
      width: 90%;
    }
    &--sub-description {
      @include regular-text;
      color: $gray;
      font-size: .8rem;
    }
    &--content {
      margin: 2rem 0;
      &--title {
        @include regular-text;
        font-weight: 700;
        margin-bottom: .5rem;
        color: $black;
      }
      &--list {
        color: $primary;
        margin-left: -1.4rem;
        font-size: .9rem;
      }
      &--description {
        @include regular-text;
        color: $gray;
        margin: 2rem 0;
      }
    }
  }
  &__right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    &--form {
      width: 70%;
    }
    @include tablet {
      margin-bottom: 2rem;
      &--form {
        width: 90%;
      }
    }
  }
  &__mobile-image-container {
    max-height: 300px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    &--image {
      height: 100%;
      width: 100%;
    }
  }
}

.service-detail-screen-background-layer {
  z-index: -1;
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  @include tablet {
    display: none;
  }
  &__left {
    flex: 1;
  }
  &__right {
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
