.book-driver-form {
  width: 80%;
  margin: 0 auto;
  padding: 2rem;
  min-height: 500px;
  position: relative;
  @include tablet {
    padding: 1rem;
  }
  &__title {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  &__form-container {
    display: flex;
    flex-direction: column;
    &--two-elements {
      display: flex;
      flex-direction: row;
      margin: 0.25rem 0;
      @include mobile {
        flex-direction: column;
        margin: 0;
      }
      &--element:first-of-type {
        margin-right: 0.5rem !important; // Needed to override MUI base style
      }
      @include mobile {
        &--element:first-of-type {
          margin-right: 0rem !important; // Needed to override MUI base style
        }
        &--element {
          margin: 0.25rem 0 !important; // Needed to override MUI base style
        }
      }
    }
    &--bottom-description {
      width: 100%;
      text-align: center;
      @include regular-text;
      color: $primary;
      margin-top: 1rem;
      text-decoration: none;
    }
  }
}
