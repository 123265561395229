.navigation-link {
  color: $white;
  text-decoration: none;
  margin-left: 2rem;
  position: relative;
  @include tablet {
    margin-left: 0rem;
    margin: .5rem 0;
  }
  &.inverted {
    color: $black;
  }
  &:after {    
    background: none repeat scroll 0 0 transparent;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: $primary;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  &:hover:after { 
    width: 100%; 
    left: 0; 
  }
}
