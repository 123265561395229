@import './Blocks/ContactForm/ContactForm';
@import './Blocks/Map/Map';

.contact-section {
  min-height: calc(100vh - 162px); // Total screen size minus the Footer
  display: flex;
  flex-direction: row;
  @include desktop {
    flex-direction: column;
  }
  &__left {
    flex: 3;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 2rem;
    background-repeat: no-repeat;
    background-size: cover;
    @include desktop {
      justify-content: center;
    }
    &--container {
      width: 100%;
      max-width: 830px;
    }
    &--title {
      @include container-left-padding;
      @include title-text;
      padding-top: 2rem;
      color: $white;
      margin-bottom: 2rem;
    }
    &--form-container {
      width: 100%;
      &--form {
        @include tablet {
          width: calc(100% - 48px);
        }
      }
    }
  }
  &__right {
    flex: 2;
    position: relative;
    min-height: 550px;
    &--title {
      position: relative;
      top: 2rem;
      @include container-left-padding;
      @include title-text;
      color: $black;
      margin-bottom: 2rem;
      z-index: 1000;
    }
    &--map-container {
      height: 100%;
      width: 100%;
      top: 0;
      position: absolute;
    }
  }
}
