.footer-top {
  background-color: $backgroundGreen;
  min-height: 8rem;
  padding: 2rem 0;
  .navigation-container {
    @include tablet {
      flex-direction: column;
      align-items: center;
    }
    &__title {
      font-size: 1.25rem;
      @include tablet {
        margin-bottom: 1rem;
      }
    }
  }
}

.footer-bottom {
  background-color: $primary;
  color: $white;
  padding: .5rem 0;
  text-transform: uppercase;
  text-align: center;
  font-size: .8rem;
}
