$font-family: 'Work Sans', sans-serif;
$baseFontSize: 16px;

@mixin navigation-text {
  text-transform: uppercase;
  font-weight: 700;
}

@mixin title-text {
  font-weight: 700;
  font-size: 2.5rem;
}

@mixin regular-text {
  font-weight: 300;
  font-size: 1rem;
}

html {
  font-size: $baseFontSize;
  @include mobile {
    font-size: $baseFontSize * 0.9;
  }
}
