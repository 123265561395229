.partners-section {
  padding: 2.5rem 0;
  &__title {
    @include title-text;
    text-align: center;
    padding: 2.5rem 0;
  }
  &__list {
    padding: 1rem 0;
    &--element {
      padding: 3rem;
      @include mobile {
        padding: 0;
      }
      &--image {
        margin: 0 auto;
        @include mobile {
          max-width: 50%;
          display: block;
        }
      }
    }
  }
}
