@import './Components/NavigationLink';
@import './Components/MobileNavigationLink';

.navigation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include navigation-text();
  color: $white;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $white;
    text-decoration: none;
    &.inverted {
      color: $black;
    }
  }
  &__elements {
    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &--mobile {
      .hamburger-menu-icon {
        color: $white;
        &.inverted {
          color: $black;
        }
      }
    }
  }
}

.mobile-menu {
  width: 220px;
  &__elements {
    @include navigation-text();
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
}
