.about-us-section {
  position: relative;
}

.about-us-section-top-layer {
  height: 100vh;
  display: flex !important; // Needed to override MUI style just for this Container component
  flex-direction: row;;
  @include tablet {
    flex-direction: column;
    height: auto;
    padding: 2rem 0;
  }
  &__left {
    flex: 1;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      @include title-text;
    }
    .line {
      @include line;
    }
    .description {
      @include regular-text;
      margin: .5rem 0;
      color: $gray;
    }
  }
  &__right {
    flex: 1;
    @include tablet {
      display: none;
    }
  }
}

.about-us-section-background-layer {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  @include tablet {
    position: relative;
    height: auto;
  }
  &__left {
    flex: 1;
    @include tablet {
      display: none;
    }
  }
  &__right {
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    @include tablet {
      height: 300px;
      align-items: center;
    }
    &--image {
      height: 100%;
      width: auto;
      @include tablet {
        height: auto;
      }
    }
  }
}
