@import './Blocks/BookDriverForm/BookDriverForm';

.header-section-top {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.header-section {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  &__left {
    height: 100%;
    flex: 3;
    color: $white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 3rem;
    @include tablet {
      padding-bottom: 4rem;
    }
    .title {
      margin-top: 13rem;
      font-weight: 700;
      font-size: 3rem;
      @include mobile {
        font-size: 3rem;
        margin-top: 10rem;
      }
    }
    .line {
      @include line;
    }
    .description {
      @include regular-text;
      margin-top: 1rem;
      @include mobile {
        font-size: 1.2rem;
      }
    }
  }
  &__rigth {
    flex: 2;
    display: flex;
    align-items: center;
    &--form {
      margin-top: 4rem;
      margin-bottom: 4rem;
      @include tablet {
        top: -8rem;
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
  &__button {
    position: absolute !important; // Overrides MUI positioning for this case
    bottom: 1rem;
    left: calc(50% - 25.5px);
    @include tablet {
      display: none !important; // Overrides MUI display for this case
    }
  }
}
