.contact-form {
  margin-left: 5%;
  margin-right: 15%;
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: row;
  @include tablet {
    margin: 0 auto;
    flex-direction: column;
    padding: 1rem;
  }
  &__title {
    @include regular-text;
    font-weight: 700;
    color: $black;
    margin-bottom: .5rem;
  }
  &__info {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: .5rem 0;
    &--text {
      color: $gray;
      text-decoration: none;
    }
  }
  &__info-streched {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__line {
    @include line;
    width: 100%;
    border-width: 1px;
    margin: 1rem 0;
  }
  &__left {
    padding: 1rem;
    border-right: 1px solid $ligthgray;
    flex: 1;
    @include tablet {
      border-right: none;
    }
  }
  &__right {
    padding: 1rem;
    flex: 1;
  }
  &__form-container {
    display: flex;
    flex-direction: column;
  }
}
